import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { streamFetch } from '../utils'

/**
 * downloadName sets name of first download (after formatting, see function).
 * Subsequent downloads from expor history will use the name under which was saved in
 * the bucket by the backend export code. For consistency make sure these match.
 */
export const useStreamDownload = ({
  url,
  body,
  downloadName,
}: {
  url: string
  body?: string
  downloadName: string
}) => {
  const formattedDate = format(new Date(), 'yyyy MM dd')
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      return streamFetch({
        url,
        body,
      })
    },
    onSuccess: (data) => {
      const fileName = `NET PURPOSE - ${downloadName} - ${formattedDate}.xlsx`
      const downloadURL = URL.createObjectURL(data)
      const a = document.createElement('a')
      a.href = downloadURL
      a.download = fileName
      a.click()
      URL.revokeObjectURL(downloadURL)
      queryClient.invalidateQueries({ queryKey: ['listExports'] })
    },
  })
}
