import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import qs from 'qs'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useDebounceValue } from 'usehooks-ts'
import { Operator, TWELVE_HOURS_MS } from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { apiConfig } from '../../config'
import {
  ApiError,
  CreateEntity,
  EntityWithDetails,
  ExportFormatsEnum,
  EstimatedData as GeneratedEstimatedData,
  LatestData as GeneratedLatestData,
  MergeTwoRecords,
  PortfolioWithEntityDetail,
  Rollup,
} from '../../generated/facts'
import { GeneratedFactApi } from '../../GeneratedApi'
import {
  EntityPortfolio,
  reverseEntityPortfolioFieldMap,
  transformEntityPortfolio,
} from '../../models'
import { ReverseFieldMap } from '../../queryBuilder'
import { Camelize, camelToSnakeKeys, snakeToCamelKeys } from '../../utils'
import { useStreamDownload } from '../useStreamDownload'
import { getPaginationConfig, useUrlApiConnector } from '../useUrlTableApiConnector'
import { useAsyncTaskWithAction } from './useAsyncTasks'
import {
  Entity,
  EntityWithQuestionDetailsFilters,
  reverseEntityWithQuestionDetailsFieldMap,
  reverseTimeSeriesFieldMap,
  TimeSeriesFact,
  transformEntity,
  transformEntityDataByQuestionId,
  transformTimeSeriesData,
} from './utils'

export const entityTableToApiFieldMap: Partial<ReverseFieldMap<keyof Entity | 'factsetId'>> = {
  id: 'entity_id',
  name: 'name',
  primaryIsin: 'primary_isin',
  headquarters: 'headquarters',
  sector: 'sector',
  industry: 'industry',
  latestReport: 'latest_report',
  marketCap: 'market_cap',
  alignedSdgs: 'sdg_goals',
  factsetId: 'identifiers.value',
}

export const PAGINATED_ENTITIES_QUERY_CACHE_KEY = 'paginatedEntities'

export const usePaginatedEntities = ({
  perPage,
  defaultParams,
  useUrlSync = true,
  enabled = true,
  staleTime,
}: {
  perPage?: number
  defaultParams?: {
    is_covered?: boolean
    with_details?: boolean
    // true: it will return all entities that have at least one accepted estimation.
    // false: it will return all entities that have only created estimations.
    has_estimated_data?: boolean
    'estimations.technology_id'?: string
    limit?: number
    sort_by?: keyof Entity
  }
  useUrlSync?: boolean
  enabled?: boolean
  staleTime?: number
}) => {
  const { queryString, filterConfig, initialPaginationConfig } = useUrlApiConnector<Entity>({
    tableToApiFieldMap: entityTableToApiFieldMap,
    urlKey: PAGINATED_ENTITIES_QUERY_CACHE_KEY,
    ...(defaultParams ? { defaultParams } : {}),
    ...(perPage ? { perPage } : {}),
    urlSyncEnabled: useUrlSync,
  })

  const { data, ...rest } = useQuery({
    queryKey: [PAGINATED_ENTITIES_QUERY_CACHE_KEY, queryString],

    queryFn: () =>
      GeneratedFactApi.entities.listEntities({
        q: queryString,
      }),
    placeholderData: keepPreviousData,
    ...(staleTime ? { staleTime } : {}),
    enabled,
  })

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  const transformedEntities = data?.results
    ? data.results.map((entity: EntityWithDetails) => transformEntity(snakeToCamelKeys(entity)))
    : undefined

  return {
    ...rest,
    data: transformedEntities,
    filterConfig,
    paginationConfig,
    total: data?.total,
  }
}

export const useCreateEntity = () =>
  useMutation({
    mutationFn: (payload: Camelize<CreateEntity>) =>
      GeneratedFactApi.entities.createEntity({ requestBody: camelToSnakeKeys(payload) }),
  })

export const useUpdateEntity = ({
  method = 'put',
  onSuccess,
}: { method?: 'put' | 'patch'; onSuccess?: () => void } = {}) =>
  useMutation({
    mutationFn: ({
      entityId,
      payload,
    }: {
      entityId: number
      // Generated BE type is just Record<string, any>, update if this changes.
      payload: Record<string, unknown>
    }) =>
      method === 'put'
        ? GeneratedFactApi.entities.putUpdateEntity({
            instanceId: entityId,
            requestBody: camelToSnakeKeys(payload),
          })
        : GeneratedFactApi.entities.patchUpdateEntity({
            instanceId: entityId,
            requestBody: camelToSnakeKeys(payload),
          }),
    onSuccess: () => onSuccess?.(),
  })

export const useMergeEntities = ({ onSuccess }: { onSuccess?: () => void } = {}) =>
  useMutation({
    mutationFn: (payload: MergeTwoRecords) =>
      GeneratedFactApi.entities.mergeEntities({ requestBody: payload }),
    onSuccess: () => onSuccess?.(),
  })

export const ENTITIES_BY_ID_QUERY_CACHE_KEY = 'entitiesByIds'

export const useEntitiesById = ({ entityIds }: { entityIds: number[] }) => {
  const uniqueIds = useMemo(() => [...new Set(entityIds)].map((id) => `ids=${id}`), [entityIds])

  const { data, ...rest } = useQuery({
    queryKey: [ENTITIES_BY_ID_QUERY_CACHE_KEY, uniqueIds],
    queryFn: () =>
      GeneratedFactApi.entities
        .listEntities({
          q: uniqueIds.join('&'),
        })
        .then(({ results }) => snakeToCamelKeys(results || []).map(transformEntity)),
    enabled: uniqueIds.length > 0,
    staleTime: TWELVE_HOURS_MS,
  })

  const recordsById = useMemo(
    () =>
      data?.reduce(
        (acc, record) => ({ [record.id]: record, ...acc }),
        {} as Record<number, Entity>,
      ),
    [data],
  )

  return {
    data: recordsById,
    ...rest,
  }
}

export const useEntity = ({
  entityId,
  defaultParams,
  staleTime,
  enabled = true,
}: {
  entityId: number | undefined
  defaultParams?: {
    with_details?: boolean
  }
  staleTime?: number
  enabled?: boolean
}) => {
  const queryString = qs.stringify(defaultParams, {
    skipNulls: true,
    arrayFormat: 'repeat',
  })

  const { data, ...rest } = useQuery({
    queryKey: ['entity', entityId, queryString],

    queryFn: () =>
      entityId
        ? GeneratedFactApi.entities.getEntity({
            entityId,
            ...(!!queryString ? { q: queryString } : {}),
          })
        : undefined,

    enabled: !!entityId && !!enabled,
    ...(staleTime ? { staleTime } : {}),
  })

  const transformedEntity = useMemo(
    () => (data ? transformEntity(snakeToCamelKeys(data)) : undefined),
    [data],
  )

  return {
    ...rest,
    data: transformedEntity,
  }
}

export const useEntityPortfolios = ({ entityId }: { entityId: number }) => {
  const queryCacheKeyRoot = 'entityPortfolios'

  const { queryString, filterConfig, initialPaginationConfig } =
    useUrlApiConnector<EntityPortfolio>({
      tableToApiFieldMap: reverseEntityPortfolioFieldMap,
      urlKey: queryCacheKeyRoot,
    })

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, queryString],

    queryFn: () =>
      GeneratedFactApi.entities.getEntityPortfolios({
        entityId,
        q: queryString,
      }),
    placeholderData: keepPreviousData,
    enabled: !!entityId,
  })

  const paginationConfig = getPaginationConfig({
    numResults: data?.length,
    paginationConfig: initialPaginationConfig,
  })

  const transformedEntityPortfolios = data
    ? data.map((entityPortfolio: PortfolioWithEntityDetail) =>
        transformEntityPortfolio(snakeToCamelKeys(entityPortfolio)),
      )
    : undefined

  return {
    ...rest,
    data: transformedEntityPortfolios,
    filterConfig,
    paginationConfig,
    total: data?.length,
  }
}

type GeneratedTimeseriesReturnType = ReturnType<typeof GeneratedFactApi.entities.getTimeseriesFacts>

const getTimeseriesFacts = (
  entity_id: number,
  query: Record<string, unknown> = {},
): GeneratedTimeseriesReturnType =>
  // NOTE: this supposed to be GeneratedFactApi.entities.getTimeseriesFacts but the way it generates
  // is not compatible with our api pattern.
  // The generated getTimeseriesFacts takes camelCased params which are missing filters we send from FE,
  // such as questionId, unit.name, theme, sdg_goal.
  GeneratedFactApi.request.request({
    method: 'GET',
    url: '/api/v1/entities/{entity_id}/timeseries',
    path: {
      entity_id,
    },
    query,
    errors: {
      404: 'Not Found',
      422: 'Validation Error',
    },
  })

export const useEntityTimeSeries = ({
  defaultParams,
}: {
  defaultParams: {
    entity_id: number
    n_years: number
    include_sdg_alignment: boolean
  }
}) => {
  const { queryString, filterConfig, initialPaginationConfig } = useUrlApiConnector<TimeSeriesFact>(
    {
      tableToApiFieldMap: reverseTimeSeriesFieldMap,
      urlKey: 'entityTimeseries',
      defaultParams,
    },
  )
  const { entity_id } = defaultParams
  const query = qs.parse(queryString)

  const { data, ...rest } = useQuery({
    queryKey: ['entityTimeseries', queryString],
    queryFn: () => getTimeseriesFacts(entity_id, query),
    enabled: !!(entity_id && queryString),
    staleTime: TWELVE_HOURS_MS,
    placeholderData: keepPreviousData,
  })

  const transformedTimeSeriesData = useMemo(
    () =>
      data?.results
        ? data?.results.map((d) => transformTimeSeriesData(snakeToCamelKeys(d)))
        : undefined,
    [data?.results],
  )

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: transformedTimeSeriesData,
    filterConfig,
    paginationConfig,
  }
}

export const ENTITY_DATA_BY_QUESTION_ID_QUERY_CACHE_KEY = 'entityDataByQuestionId'

export const useEntityDataByQuestionId = ({
  defaultParams,
  questionId,
  withTotalRow,
  urlSyncEnabled,
}: {
  defaultParams?: { [key: string]: string | number | string[] }
  questionId: number | null
  withTotalRow: boolean
  urlSyncEnabled: boolean
}) => {
  const { queryString, filterConfig, initialPaginationConfig } =
    useUrlApiConnector<EntityWithQuestionDetailsFilters>({
      tableToApiFieldMap: reverseEntityWithQuestionDetailsFieldMap,
      urlKey: ENTITY_DATA_BY_QUESTION_ID_QUERY_CACHE_KEY,
      defaultParams,
      withTotalRow,
      urlSyncEnabled,
    })
  const portfolioId = defaultParams?.['portfolio_id']

  const { data, ...rest } = useQuery({
    // include portfolioId for easier cache invalidation on ie., portfolio AUM update
    queryKey: [ENTITY_DATA_BY_QUESTION_ID_QUERY_CACHE_KEY, queryString, questionId, portfolioId],

    queryFn: () =>
      questionId
        ? GeneratedFactApi.entities.listQuestionEntitiesData({
            questionId,
            q: queryString,
          })
        : undefined,

    enabled: !!questionId && !!queryString,
    staleTime: TWELVE_HOURS_MS,
    placeholderData: keepPreviousData,
  })

  const transformedEntityDataByQuestionId = useMemo(
    () =>
      data?.results
        ? data?.results.map((d) => transformEntityDataByQuestionId(snakeToCamelKeys(d)))
        : undefined,
    [data?.results],
  )

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: {
      ...data,
      results: transformedEntityDataByQuestionId,
      totals: {
        company: snakeToCamelKeys<Rollup | undefined>(data?.totals.company),
        portfolio: snakeToCamelKeys<Rollup | undefined>(data?.totals.portfolio),
      },
    },
    filterConfig,
    paginationConfig,
  }
}

export const useCreateEntities = () =>
  useAsyncTaskWithAction((values: { file: File; cohort: number; force: boolean }) =>
    GeneratedFactApi.entities
      .createEntities({
        formData: {
          entities_csv: values.file,
          cohort: values.cohort,
          force: values.force,
        },
      })
      // @ts-ignore - Not sure why data is unknown here
      .then((data) => data.result_id),
  )

export const useEntityEstimationsSummary = ({ entityId }: { entityId: number }) =>
  useQuery({
    queryKey: ['entityEstimationsSummary', entityId],
    queryFn: () =>
      GeneratedFactApi.entities.getEntityEstimationsSummary({ entityId }).then(snakeToCamelKeys),
    staleTime: TWELVE_HOURS_MS,
  })

export type EstimatedData = Camelize<GeneratedEstimatedData>

export const useEntityEstimatedData = ({
  entityId,
  questionId,
  year,
  enabled,
}: {
  entityId: number
  questionId: number
  year: number | undefined
  enabled: boolean
}) =>
  useQuery({
    queryKey: ['entityEstimatedData', entityId, questionId, year],

    queryFn: () =>
      year
        ? GeneratedFactApi.entities
            .getEstimatedData({ entityId, questionId, year })
            .then((data: GeneratedEstimatedData[]) => data.map(snakeToCamelKeys))
        : undefined,
    enabled: !!(entityId && questionId && year && enabled),
    staleTime: TWELVE_HOURS_MS,
  })

export type LatestData = Camelize<GeneratedLatestData>

export const useEntityLatestData = ({
  entityId,
  questionId,
  enabled,
}: {
  entityId: number
  questionId: number
  enabled: boolean
}) =>
  useQuery({
    queryKey: ['latestData', entityId, questionId],

    queryFn: () =>
      GeneratedFactApi.entities
        .getLatestData({ entityId, questionId })
        .then((data: GeneratedLatestData) => snakeToCamelKeys(data)),
    enabled,
    staleTime: TWELVE_HOURS_MS,
  })

export const useGetItemsWithEntityNames = <Item extends { entityId?: number }>({
  items,
}: {
  items: Item[]
}) => {
  const entityIds = items.map((item: Item) => item.entityId).filter(valueIsDefined)
  const { data: entitiesById, isFetching } = useEntitiesById({ entityIds })

  if (isFetching) {
    return { data: [], isFetching }
  }

  const itemsWithEntityNames = items.map((item) => {
    const entityName = item?.entityId ? entitiesById?.[item?.entityId]?.name : ''
    return {
      ...item,
      ...(entityName ? { entityName } : {}),
    }
  })

  return {
    data: itemsWithEntityNames,
    isFetching,
  }
}

export const useEntitiesSearch = ({
  enabled = true,
  useUrlSync,
  defaultParams,
  staleTime,
}: {
  enabled?: boolean
  useUrlSync: boolean
  defaultParams?: {
    is_covered?: boolean
  }
  staleTime?: number
}) => {
  const { data, filterConfig, isFetching } = usePaginatedEntities({
    perPage: 10,
    useUrlSync,
    enabled,
    ...(defaultParams ? { defaultParams } : {}),
    ...(staleTime ? { staleTime } : {}),
  })

  const [searchTerm, setSearchTerm] = useState('')

  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 300)

  useEffect(() => {
    // NOTE: this is to avoid empty name= param
    filterConfig.setFilters('name', [
      { operator: Operator.Contains, value: debouncedSearchTerm || undefined },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  return {
    data: data || [],
    isFetching,
    onEntitySearch: setSearchTerm,
  }
}

const defaultUploadErrorMessage = 'An error occurred. Please check your file and try again.'

export const useUploadSectorToTaxonomy = ({
  onError,
}: {
  onError: Dispatch<SetStateAction<string | null>>
}) =>
  useMutation({
    mutationFn: (formData: { upload_file: Blob }) =>
      GeneratedFactApi.entities.uploadSectorToTaxonomy({ formData }),
    onError: (err: ApiError) =>
      // @ts-expect-error - err.body is unknown type
      onError(err.body?.msg || err.body?.detail || defaultUploadErrorMessage),
  })

export const useUploadTaxonomyAlignment = ({
  onError,
}: {
  onError: Dispatch<SetStateAction<string | null>>
}) =>
  useMutation({
    mutationFn: (formData: { upload_file: Blob }) =>
      GeneratedFactApi.entities.uploadTaxonomyAlignment({ formData }),
    onError: (err: ApiError) =>
      // @ts-expect-error - err.body is unknown type
      onError(err.body?.msg || err.body?.detail || defaultUploadErrorMessage),
  })

export const useEntityActivitiesSDGRevenueAlignment = ({ entityId }: { entityId: number }) => {
  const queryCacheKeyRoot = 'entityActivitiesSDGRevenueAlignment'

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, entityId],
    queryFn: () =>
      entityId
        ? GeneratedFactApi.entities.getEntityActivitiesSdgRevenueAlignment({ entityId })
        : undefined,
    enabled: !!entityId,
    staleTime: TWELVE_HOURS_MS,
    // Override default retry behaviour in GlobalErrorBanner.tsx to avoid
    // retrying unnecessarily and showing the alert banner.
    retry: (failureCount, err) => {
      if (err.message === 'Not Found') {
        return false
      }
      return failureCount < 3
    },
  })

  return {
    ...rest,
    data: snakeToCamelKeys(data),
  }
}

export type EntityActivitiesSDGRevenueAlignment = NonNullable<
  ReturnType<typeof useEntityActivitiesSDGRevenueAlignment>['data']
>

export type EntityActivitiesSDGRevenueAlignmentActivities = NonNullable<
  EntityActivitiesSDGRevenueAlignment['activities']
>

export const useUploadSegmentToTaxonomy = ({
  onError,
}: {
  onError: Dispatch<SetStateAction<string | null>>
}) =>
  useMutation({
    mutationFn: (formData: { upload_file: Blob }) =>
      GeneratedFactApi.entities.uploadSegmentToTaxonomy({ formData }),
    onError: (err: ApiError) =>
      // @ts-expect-error - err.body is unknown type
      onError(err.body?.msg || err.body?.detail || defaultUploadErrorMessage),
  })

export const useExportEntity = ({
  entityId,
  onSuccess,
}: {
  entityId: number
  onSuccess: (token: string) => void
}) =>
  useMutation({
    mutationFn: () => GeneratedFactApi.entities.exportEntity({ entityId }),
    onSuccess: (downloadableFileRequest) => onSuccess(downloadableFileRequest.result_id),
  })

export const useEntitiesLookup = () =>
  useAsyncTaskWithAction(
    ({
      file,
      includeNpData,
      identifierCol,
      includeFundamentals,
      includeSectors,
      shouldEnsurePublicEntities,
    }: {
      file: File
      identifierCol: string
      includeNpData: boolean
      includeFundamentals: boolean
      includeSectors: boolean
      shouldEnsurePublicEntities: boolean
    }) =>
      GeneratedFactApi.entities
        .lookupEntities({
          formData: {
            security_file: file,
          },
          identifierType: 'isin',
          includeNpData,
          outputFormat: ExportFormatsEnum.CSV,
          identifierCol,
          includeFundamentals,
          includeSectors,
          shouldEnsurePublicEntities,
        })
        .then((data) => data.result_id),
  )

export const useEntitiesLookupSFDR = () =>
  useAsyncTaskWithAction((values: { inputFile: File; isinColumnName: string }) =>
    GeneratedFactApi.entities
      .lookupSfdr({
        formData: {
          security_file: values.inputFile,
          identifier_type: 'isin',
          identifier_col: values.isinColumnName,
        },
      })
      .then((data) => data.result_id),
  )

export const useEntityActivitiesSdgRevenueAlignmentByTaxon = ({
  entityId,
  taxonId,
  enabled,
}: {
  entityId: number
  taxonId: number | undefined
  enabled: boolean
}) =>
  useQuery({
    queryKey: ['entityActivitiesSdgRevenueAlignmentByTaxon', entityId, taxonId],
    queryFn: () =>
      taxonId
        ? GeneratedFactApi.entities
            .getEntityActivitiesSdgRevenueAlignmentByTaxon({
              entityId,
              taxonId,
            })
            .then(snakeToCamelKeys)
        : undefined,
    enabled: enabled && !!taxonId,
    staleTime: TWELVE_HOURS_MS,
  })

export type EntityActivityContributionChild = NonNullable<
  ReturnType<typeof useEntityActivitiesSdgRevenueAlignmentByTaxon>['data']
>['children'][number]

export const useExportEntityActivitiesXlsx = ({
  entityId,
  entityName,
}: {
  entityId: number
  entityName: string
}) =>
  useStreamDownload({
    url: `${apiConfig.factsHost}/api/v1/entities/sdg_revenue_alignment/${entityId}/activities/export`,
    downloadName: `${entityName} - SDG Revenue Activities`,
  })

export const useExportEntityDashboardXlsx = ({
  entityId,
  entityName,
  metricConfigId,
  metricConfigName,
}: {
  entityId: number
  entityName: string
  metricConfigId: string
  metricConfigName: string
}) =>
  useStreamDownload({
    url: `${apiConfig.factsHost}/api/v1/entities/dashboard/export`,
    downloadName: `${entityName} - Dashboard - ${metricConfigName}`,
    body: JSON.stringify({
      entity_id: entityId,
      export_options: { metrics_config_id: metricConfigId },
    }),
  })
