import qs from 'qs'
import { FiltersOperator, PaginationConfig } from '@netpurpose/types'
import { apiConfig } from '../config'
import { formatFilterParams, formatSortParams, ReverseFieldMap } from '../queryBuilder'
import { useFilters } from './useFilters'
import { usePagination } from './usePagination'
import { useSyncUrlWithFiltersAndPagination } from './useSyncUrlWithFiltersAndPagination'

export const useUrlApiConnector = <Model>({
  tableToApiFieldMap,
  urlKey,
  defaultParams,
  perPage = apiConfig.defaultFetchSize,
  filtersOperator,
  multiFieldSortingEnabled = false,
  withTotalRow = false,
  urlSyncEnabled = true,
  defaultSortBy,
}: {
  tableToApiFieldMap: Partial<ReverseFieldMap<keyof Model>>
  urlKey: string
  // NOTE: Used for setting API flags not visible to the user e.g type=theme, use_flag_foo=true etc.
  defaultParams?: { [key: string]: string | number | string[] | boolean } | undefined
  perPage?: number
  filtersOperator?: FiltersOperator
  multiFieldSortingEnabled?: boolean
  withTotalRow?: boolean
  urlSyncEnabled?: boolean
  defaultSortBy?: keyof Model
}) => {
  // NOTE: STEP ONE - get filters and pagination state
  const filterConfig = useFilters<Model>({
    urlKey,
    queryOptions: { useUrlSync: urlSyncEnabled },
    multiFieldSortingEnabled,
  })

  const paginationConfig = usePagination({
    perPage,
    withTotalRow,
  })

  const { pageSize, currentPage } = paginationConfig
  const { filters, sorting, sortOrder } = filterConfig
  const apiPage = currentPage - 1

  // NOTE: STEP TWO - update URL state [optional]
  useSyncUrlWithFiltersAndPagination({
    queryCacheKey: urlKey,
    filters,
    sorting,
    sortOrder,
    pagination: {
      currentPage,
      pageSize,
    },
    enabled: !!urlSyncEnabled,
  })

  const limit = pageSize ? pageSize : apiConfig.defaultFetchSize

  // NOTE: STEP THREE - combine pagination, filters and sorting ready for stringifying
  const combinedParams = {
    ...(limit ? { limit } : {}),
    ...{ page: apiPage || 0 },
    ...formatFilterParams(filters, tableToApiFieldMap, filtersOperator),
    ...formatSortParams(sorting, sortOrder, tableToApiFieldMap),
    ...(defaultParams ? { ...defaultParams } : {}),
    // Including sort_by as part of the default params conflicts with actual sorting
    // filters when applied, so we need to use it separately.
    ...(defaultSortBy && Object.keys(sorting).length === 0 ? { sort_by: defaultSortBy } : {}),
  }
  const queryString = qs.stringify(combinedParams, {
    skipNulls: true,
    arrayFormat: 'repeat',
  })

  return {
    queryString,
    filterConfig,
    initialPaginationConfig: paginationConfig,
  }
}

export const getPaginationConfig = ({
  numResults,
  paginationConfig: { currentPage, pageSize, ...pagination },
}: {
  numResults: number | undefined
  paginationConfig: PaginationConfig
}): PaginationConfig => ({
  ...pagination,
  currentPage,
  pageSize,
  ...(numResults ? { totalCount: numResults } : {}),
  tablePaginationConfig: {
    ...pagination.tablePaginationConfig,
    showSizeChanger: false,
    hideOnSinglePage: true,
    current: currentPage,
    ...(numResults ? { total: numResults } : {}),
  },
})
