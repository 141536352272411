import { getToken } from '../auth'

// axios doesn't seem to work well with StreamingResponse so we have to use fetch directly
export const streamFetch = async ({
  url,
  body,
}: {
  url: string
  body?: string | undefined
}): Promise<Blob> => {
  const token = await getToken()
  const response = await fetch(url, {
    method: 'POST',
    ...(body && { body }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  if (response.status !== 200) {
    throw new Error(`Export request failed with status ${response.status}`)
  }
  const reader = response?.body?.getReader()

  const chunks = []
  let done = false

  while (!done) {
    const readResult = await reader?.read()
    if (readResult) {
      const { value, done: readerDone } = readResult
      if (value) {
        chunks.push(value)
      }
      done = readerDone
    }
  }
  // Convert Uint8Array chunks into a Blob
  return new Blob(chunks, { type: 'application/octet-stream' })
}
