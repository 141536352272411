import generatePicker, { PickerProps } from 'antd/es/date-picker/generatePicker'
import { parse } from 'date-fns'
import { FieldProps } from 'formik'
import { Field } from 'formik-antd'
import { FormikFieldProps } from 'formik-antd/es/FieldProps'
import dateFnsGenerateConfig from 'rc-picker/es/generate/dateFns'

export type DatePickerProps = Omit<PickerProps<Date>, 'onChange' | 'disabledDate'> &
  FormikFieldProps & {
    onChange?: (
      date: Date | null,
      dateString?: string | null,
    ) => void | ((date: Date | null) => void)
    disabledDate?: (date: Date) => boolean
  }

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig)

export const FormikAntdDatePicker = ({
  name,
  validate,
  onChange,
  fast,
  disabledDate = () => false,
  ...restProps
}: DatePickerProps) => {
  const format = (value: number | string) => {
    return restProps.picker === 'year' ? parse(`${value}`, 'yyyy', new Date()) : new Date(value)
  }

  return (
    // @ts-expect-error
    <Field name={name} validate={validate} fast={fast}>
      {({
        form: { setFieldValue, setFieldTouched },
        field: { value },
      }: FieldProps<number | string>) => (
        <DatePicker
          aria-label={name}
          value={value ? format(value) : null}
          disabledDate={(date) => disabledDate(date)}
          onChange={(date, dateString) => {
            setFieldValue(name, date || undefined)
            setFieldTouched(name, true, false)
            if (onChange) {
              onChange(date, dateString)
            }
          }}
          {...restProps}
        />
      )}
    </Field>
  )
}
